/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type { VePhonon, VePhononInterface } from "../VePhonon";

const _abi = [
  {
    name: "CommitOwnership",
    inputs: [
      {
        type: "address",
        name: "admin",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "ApplyOwnership",
    inputs: [
      {
        type: "address",
        name: "admin",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "Deposit",
    inputs: [
      {
        type: "address",
        name: "provider",
        indexed: true,
      },
      {
        type: "uint256",
        name: "value",
        indexed: false,
      },
      {
        type: "uint256",
        name: "locktime",
        indexed: true,
      },
      {
        type: "int128",
        name: "type",
        indexed: false,
      },
      {
        type: "uint256",
        name: "ts",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "Withdraw",
    inputs: [
      {
        type: "address",
        name: "provider",
        indexed: true,
      },
      {
        type: "uint256",
        name: "value",
        indexed: false,
      },
      {
        type: "uint256",
        name: "ts",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "Supply",
    inputs: [
      {
        type: "uint256",
        name: "prevSupply",
        indexed: false,
      },
      {
        type: "uint256",
        name: "supply",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "token_addr",
      },
      {
        type: "string",
        name: "_name",
      },
      {
        type: "string",
        name: "_symbol",
      },
      {
        type: "string",
        name: "_version",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    name: "commit_transfer_ownership",
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "addr",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "apply_transfer_ownership",
    outputs: [],
    inputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "commit_smart_wallet_checker",
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "addr",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "apply_smart_wallet_checker",
    outputs: [],
    inputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "toggleEmergencyUnlock",
    outputs: [],
    inputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "recoverERC20",
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "token_addr",
      },
      {
        type: "uint256",
        name: "amount",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "get_last_user_slope",
    outputs: [
      {
        type: "int128",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "addr",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "user_point_history__ts",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "_addr",
      },
      {
        type: "uint256",
        name: "_idx",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "locked__end",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "_addr",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "checkpoint",
    outputs: [],
    inputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "deposit_for",
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "_addr",
      },
      {
        type: "uint256",
        name: "_value",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "create_lock",
    outputs: [],
    inputs: [
      {
        type: "uint256",
        name: "_value",
      },
      {
        type: "uint256",
        name: "_unlock_time",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "increase_amount",
    outputs: [],
    inputs: [
      {
        type: "uint256",
        name: "_value",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "increase_unlock_time",
    outputs: [],
    inputs: [
      {
        type: "uint256",
        name: "_unlock_time",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "withdraw",
    outputs: [],
    inputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "balanceOf",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "addr",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "balanceOf",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "addr",
      },
      {
        type: "uint256",
        name: "_t",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "balanceOfAt",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "addr",
      },
      {
        type: "uint256",
        name: "_block",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "totalSupply",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "totalSupply",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "uint256",
        name: "t",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "totalSupplyAt",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "uint256",
        name: "_block",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "totalFXSSupply",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "totalFXSSupplyAt",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "uint256",
        name: "_block",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "changeController",
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "_newController",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "token",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "supply",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "locked",
    outputs: [
      {
        type: "int128",
        name: "amount",
      },
      {
        type: "uint256",
        name: "end",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "arg0",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "epoch",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "point_history",
    outputs: [
      {
        type: "int128",
        name: "bias",
      },
      {
        type: "int128",
        name: "slope",
      },
      {
        type: "uint256",
        name: "ts",
      },
      {
        type: "uint256",
        name: "blk",
      },
      {
        type: "uint256",
        name: "fxs_amt",
      },
    ],
    inputs: [
      {
        type: "uint256",
        name: "arg0",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "user_point_history",
    outputs: [
      {
        type: "int128",
        name: "bias",
      },
      {
        type: "int128",
        name: "slope",
      },
      {
        type: "uint256",
        name: "ts",
      },
      {
        type: "uint256",
        name: "blk",
      },
      {
        type: "uint256",
        name: "fxs_amt",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "arg0",
      },
      {
        type: "uint256",
        name: "arg1",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "user_point_epoch",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "arg0",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "slope_changes",
    outputs: [
      {
        type: "int128",
        name: "",
      },
    ],
    inputs: [
      {
        type: "uint256",
        name: "arg0",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "controller",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "transfersEnabled",
    outputs: [
      {
        type: "bool",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "emergencyUnlockActive",
    outputs: [
      {
        type: "bool",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "name",
    outputs: [
      {
        type: "string",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "symbol",
    outputs: [
      {
        type: "string",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "version",
    outputs: [
      {
        type: "string",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "decimals",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "future_smart_wallet_checker",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "smart_wallet_checker",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "admin",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "future_admin",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
];

export class VePhonon__factory {
  static readonly abi = _abi;
  static createInterface(): VePhononInterface {
    return new utils.Interface(_abi) as VePhononInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): VePhonon {
    return new Contract(address, _abi, signerOrProvider) as VePhonon;
  }
}
